body {
  margin: 0px auto;
  width: 100%;
  float: left;
  font-family: "Roboto", Arial !important;
  min-height: 100vh;
  position: relative;
  /* padding-bottom: 100px; */
  box-sizing: border-box;
}
.player .detail_ad_section{
 text-align: center;
 margin-top: 10px;
 width: 100%;
 float: left;
 }
.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.wrapper {
  width: 100%;
  float: left;
}
.container_wrap {
  margin-top: 60px;
}
.dark-theme {
  /* background-color: #171719; */
  background-color: #1F1E2B;
}
.widget-svg{
  width:11px !important;
}
.star-container{
  position: relative;
    display: inline-block;
    vertical-align: middle;
  padding-right: 2px !important;
padding-left: 2px !important;
}
.movie_details .like_share{
  text-align: center;
}

.movie_details_logo img{width: 70px;}
.movie_details_logo{
  float: left;
  padding-right: 10px;

}

.movie_details .rmv{padding: 0px;}
.movie_details_text .movie_details_title{
  margin-top: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  color: #ffffff;
}
.movie_details_text .movie_details_cast{font-size: 13px;}
.movie_details_text ul , .movie_favoutite ul{
  padding: 0px;
  margin: 0px;
  list-style: none;
  color: #ccc;
}
.rate_view_button{
  margin-top: 10px;
  padding: 0px 0px 0px 0px;
    border: none;
    background-color: #FFCC33;
    border-radius: 5px;
}
.textarea_radius{
  border-radius: 20px;

}
.rupee_symbol_detail {
  font-family: "Roboto", Arial;
  margin-left: 15px;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    color: #000;
    font-weight: bold;
}
/* .banner_slider {
  padding: 70px 0px 20px;
} */
/* .verticalimage {
  height: 130px;
} */
.bannerimage {
  width: 100%;
}
/* .horizontalimage {
  height: 240px;
} */
.navbar-collapse {
  border-top: unset !important;
  box-shadow: none !important;
}
.close_icon {
  display: none;
}
/* .searchopen .search_clear {
  display: none;
} */
.searchopen .label-hint .fa-arrow-left,
.searchopen .result_area {
  display: none;
}
.page_marker1 {
  text-align: center;
}

#hungama_Player #controlsOverlay {
  z-index: 9;
  overflow-x: hidden;
}
#hungama_Player #controls > .controlButton img {
  width: 70%;
}
#hungama_Player #controls > .controlButton .svgBackground {
  width: 60%;
}
#hungama_Player .setting {
  padding-top: 10px;
}
#hungama_Player .unmute_btn,
#hungama_Player .volume_btn {
  width: 25px;
  padding-top: 8px;
}
#hungama_Player .fullscreen_button {
  width: 25px;
  padding-top: 5px;
}
#hungama_Player .setting #settingBox {
  width: 90px;
  bottom: 30px;
}
#hungama_Player .volBarContainer {
  top: -65px;
  left: -28px;
}
#hungama_Player .volume * {
  box-sizing: initial;
}
.search-box .navbar-right {
  margin-right: 100px;
}
.banner_slider .owl-carousel .owl-item {
  position: relative;
}
.banner_slider .owl-carousel .owl-item .logo_patner {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
}
.owl-carousel .owl-item .carousel_logo_patner {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
}
.filters_flex_dev .filter_logo_partner {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
}

.you-may-like_section .flex-dev,
.viewall_section .flex-dev {
  position: relative;
}
.you-may-like_section .you_may_like_logo_patner,
.viewall_section .view_all_logo_patner {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px !important;
}
button:focus {
  outline: 0;
}
.episodes_section {
  margin-top: 15px;
}
.flowplayer .divBackward.fp-controls{
  display: inline-block;
  width: auto;
  top: 47%;
  left: 25%;
 }
 .flowplayer .divForward.fp-controls{
   display: inline-block;
   width: auto;
   top: 47%;
   right: 25%;
 }
.promo_video .divBackward img, .promo_video .divForward img{display: none;}

.carousel_slider_section .slick-slide{
  padding-right: 10px;
}
.carousel_slider_section .slick-slide .center_caousel_item{
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
}
.carousel_slider_section .slick-prev:before, .slick-next:before{
   display: none;
}
.carousel_slider_section .slick-slider .slick-prev{
  background: url(../src/manorama/assets/images/carousel_arrow_left_default.png) no-repeat scroll 0 0;
  left: -30px;
  top: 48%;
  height: 45px;
  z-index: 1;
}
.carousel_slider_section .slick-slider .slick-next{
  background: url(../src/manorama/assets/images/carousel_arrow_right_default.png) no-repeat scroll 0 0;
  right: -25;
  top: 48%;
  height: 45px;
}
.carousel_slider_section .slick-slider .slick-prev:hover{
  background: url(../src/manorama/assets/images/carousel_arrow_left_on_hover.png) no-repeat scroll 0 0 !important;
}
.carousel_slider_section .slick-slider .slick-next:hover{
  background: url(../src/manorama/assets/images/carousel_arrow_right_on_hover.png) no-repeat scroll 0 0 !important;
}

.carousel_slider_section .slick-prev.slick-disabled, .carousel_slider_section .slick-next.slick-disabled{
  display: none !important;
}
.breakingowlcarousel h3{
  margin: 0px 10px;
}
.breakingowlcarousel img{
  width: 100%;
}
.audiosection{
  position: absolute;
  top: 10px;
  margin-left: 20px;
  padding: 4px 6px;
  background-color: rgba(0,0,0,.65);
  border-radius: 0.2em;
}
.audiosection img{
   width: 30px;
   cursor: pointer;
}
.audiosection h4{
  cursor: pointer;
  color:#fff;
  float: left;
  margin: 0px;
  margin-top: 6px;
  font-size: 15px;
  font-weight: 400;
  padding-right: 10px;
}
.video-js .vjs-control-bar .vjs-button{
  font-size: 12px !important;
}
.video-js .vjs-control-bar .vjs-quality-button{
  display: none;
}
.vjs-menu-button-popup .vjs-menu .vjs-menu-content li:hover{
  background-color: #2CD7F3;
}
.video-js.vjs-max-quality-selector .vjs-max-quality-selector-button .vjs-menu li.vjs-selected{
  color: #fff;
  background: none;
}
.video-js.vjs-max-quality-selector .vjs-max-quality-selector-button .vjs-menu li.vjs-selected:hover{
  background-color: #2CD7F3;
}
.video-js.vjs-max-quality-selector .vjs-max-quality-selector-button .vjs-menu-content{
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: rgba(0,0,0,0.6);
  /* position: absolute;
    bottom: 17px;
    right: 0px; */
}
.video-js .vjs-control{
  background-color: rgba(0,0,0,0.6);
}
.vjs-menu li{
  font-size: 12px !important;
  font-weight: normal;
  line-height: 23px !important;
}
/* .slick-slide .center_caousel_item:hover img {
  -moz-transform: scale(1.1);
-webkit-transform: scale(1.1);
transform: scale(1.1); 
} */


@media screen and (min-width: 992px) and (max-width: 1280px) {
  .flex-dev {
    margin: 1% !important;
    flex-basis: 18% !important;
  }
}
@media screen and (min-width: 1281px) and (max-width: 1500px) {
  .flex-dev {
    margin: 1% !important;
    flex-basis: 14.6% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .installappPopup .sliderbody.topheader_banner .reelspwa{
    margin-top: 0px !important;
  }
  .reels_header{
    display: none;
  }
  .flex-dev {
    margin: 1% !important;
    flex-basis: 23% !important;
  }
  /* .horizontalimage {
    height: 214px !important;
  } */
  .dropdown-menu-full ul > li {
    width: 24.667%;
  }
  .main-container {
    padding-bottom: 180px;
  }
  .icon-caption-image {
    width: 40px !important;
    height: 100%;
  }
  #hungama_Player .setting_button {
    width: 35px;
  }
  #hungama_Player .setting #settingBox {
    width: 74px;
    padding: 5px 10px;
  }
  #hungama_Player .setting #settingBox li {
    padding: 0px;
  }
  #hungama_Player #settingBox input[type="radio"] + label {
    font-size: 12px;
    margin-bottom: 0px;
  }
  #hungama_Player #hungama_logo {
    min-height: 25px;
  }
  #hungama_Player #settingBox input[type="radio"] + label span {
    width: 10px;
    height: 10px;
    margin: -1px 6px 0 0;
  }
  .switch_light {
    display: none;
  }
  .googlepay_img {
    width: 130px;
  }
  .apple_img {
    width: 115px;
  }
  .modal_infomsg .modal-body, .modal_infomsg .modal-footer{padding: 0px;}
  .info_footer {
    margin: 10px 108px 0px 0px !important;
  }
  .play_logo {
    bottom: 10px;
    left: 0px;
  }
  .redirect_section img{width: 100%;}
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .flex-dev {
    margin: 2% !important;
    flex-basis: 29% !important;
  }
  .viewall_title h2 {
    font-size: 20px;
  }
  .footer-list__link {
    font-size: 12px;
  }
  .copyright {
    font-size: 12px;
    text-align: center;
    display: block;
  }
  .owl-theme .owl-nav {
    display: none;
  }
  .carousel_body {

    padding: 0px;
  }
  /* .horizontalimage {
    height: 200px !important;
  } */
  .viewall_horizontal .horizontalimage {
    height: 240px !important;
  }
  .dropdown-menu-full ul > li {
    width: 49.667%;
    float: left;
    margin: 10px 0;
  }
  /* .wrapper-logo img {
    margin: 13px 0px;
  } */
  .banner_slider {
    padding-top: 105px;
  }
  .flow_player {
    width: 100%;
  }
  .movies_detail_content .movies_detail_title h2 {
    margin: 0px;
  }
  .movies_detail_content {
    padding: 5px 0px;
  }
  .main-container {
    padding-bottom: 180px;
  }
  .footer {
    /* padding: 20px 0px; */
    margin-bottom: 50px;
  }
  .signin-part input {
    width: 100%;
    font-size: 12px;
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 3px;
    height: 32px;
  }
  .signin-part .bar {
    width: 60%;
  }
  .mobile_signin {
    width: 75%;
    margin: 0px auto;
  }
  .watch_title {
    bottom: 75px;
  }
  #pageloader {
    top: 105px;
  }
  .search-hint label {
    position: fixed;
    width: 100%;
    top: 22px;
  }
  .live-search-result {
    left: 0px;
    position: fixed;
    width: 100%;
    top: 108px;
  }
  .wrapper-header .nav .dropdown-menu {
    top: 105px;
  }
  .flowplayer .divForward.fp-controls{top: 43%;}
  .flowplayer .divBackward.fp-controls{top: 43%;}
  .episodes_tabs .episodes-info span, .recommended_carousel .episodes-info span{
    width: 145px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .flex-dev {
    margin: 2% !important;
    flex-basis: 29% !important;
  }
  .viewall_title h2 {
    font-size: 20px;
  }
  .footer-list__link {
    font-size: 12px;
  }
  .copyright {
    font-size: 12px;
    text-align: center;
    display: block;
  }
  .owl-theme .owl-nav {
    display: none;
  }
  .carousel_body {
    padding: 0px;
  }
  .dropdown-menu-full ul > li {
    width: 49.667%;
    float: left;
    margin: 0px 0px;
  }
  /* .wrapper-logo img {
    margin: 13px 0px;
  } */
  /* .banner_slider {
    padding-top: 105px;
  } */
  .flow_player {
    width: 100%;
    /* min-height: 100% !important; */
  }
  .movies_detail_content .movies_detail_title h1 {
    margin: 0px;
  }
  .movies_detail_content {
    padding: 5px 0px;
  }
  .main-container {
    padding-bottom: 180px;
  }
  .footer {
    margin-bottom: 55px;
    padding: 0px;
  }
  /* .footer-list__item:before {
    display: none;
  } */
  /* .footer-content .footer-list {
    padding-top: 5px;
  } */
  .viewall_horizontal .horizontalimage {
    height: 230px !important;
  }
  /* .horizontalimage {
    height: 170px !important;
  } */
  .carosel_slider .icon-caption .icon-caption-image {
    display: none;
  }
  .success-alert {
    font-size: 12px;
  }
  .signin_youbroadbnd {
    width: 80%;
  }
  .signin-part input {
    width: 100%;
    font-size: 12px;
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 3px;
    height: 32px;
  }
  .language_selection{
    color:#fff
  }
  .signin-part .bar {
    width: 75%;
  }
  #hungama_Player .setting_button {
    width: 35px;
  }
  #hungama_Player .setting #settingBox {
    width: 64px;
    padding: 0px 10px;
  }
  #hungama_Player .setting #settingBox li {
    padding: 0px;
  }
  #hungama_Player #settingBox input[type="radio"] + label {
    font-size: 11px;
    margin-bottom: 0px;
  }
  #hungama_Player #hungama_logo {
    min-height: 25px;
  }
  #hungama_Player #settingBox input[type="radio"] + label span {
    width: 10px;
    height: 10px;
    margin: -1px 6px 0 0;
  }
  #hungama_Player #scrubber {
    top: 5px;
  }
  #hungama_Player .setting {
    display: none;
  }
  .mobile_signin {
    width: 90%;
    margin: 0px auto;
  }
  .signin_modal .modal-header {
    padding-bottom: 5px;
  }
  .carousel_body .home_carosel_title h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px !important;
    display: inline-block;
    font-family: "Roboto", Arial;
    color: #ffffff;
  }
  .carousel_body .home_carosel_title .squareimage {
    padding-top: 0px !important;
  }
  .video-socials-list {
    margin: 5px 0px !important;
  }
  .watch_title {
    bottom: 120px;
  }
  .info_text {
    font-size: 12px;
  }
  .results_empty {
    padding: 0px 8px;
  }
  .list-filter .filter-trigger span {
    font-size: 16px;
  }
  .lead-more-btn {
    padding: 10px 25px;
  }

  #pageloader {
    top: 105px;
  }
  .search-hint label {
    position: fixed;
    width: 100%;
    top: 14px;
  }
  .live-search-result {
    left: 0px;
    position: fixed;
    width: 100%;
    top: 100px;
  }
  .wrapper-header .nav .dropdown-menu {
    top: 105px;
  }
  .episodes_tabs .epi_list .epi_img_part img.verticalimage {
    height: 90px;
  }
  
  .is-fullscreen.flowplayer .divForward.fp-controls{top: 47%;}
  .is-fullscreen.flowplayer .divBackward.fp-controls{top: 47%;}
  .flowplayer .divForward.fp-controls{top: 40%;}
  .flowplayer .divBackward.fp-controls{top: 40%;}
  .redirect_section{min-height: 40vh;}
  .episodes_tabs .episodes-info span, .recommended_carousel .episodes-info span{
    width: 190px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .installappPopup .sliderbody.topheader_banner .reelspwa{
    margin-top: 0px !important;
  }

  .reels_header{
    display: none;
  }
  .installappPopup .trigger_topheader{
    margin-top: 55px;
  }
  .installappPopup .sliderbody.topheader_banner{
    margin-top: 100px !important;
  }
  .installappPopup .topheader_banner .mm_banner {
    padding-top: 85px;
  }

  .installappPopup .topheader_banner .new_tabs_topheader{
    top: 102px !important;
  }
  .installappPopup .player_section.topheader_banner{
    margin-top: 100px !important;
  }
  .installappPopup .topheader_banner .newsNestedLayout{
    margin-top: 16px !important;
  }
  .installappPopup .topheader_faqs{
    margin-top: 115px !important;
  }
 .installappPopup .viewall_rectangle.topheader_faqs{
    margin-top: 115px !important;
  }
  .installappPopup .watchlist_content{
    margin-top: 45px;
  }
  .installappPopup .my_profile_page{
    margin-top: 45px;
  }
  .viewall_section .flex-dev{
    flex-basis: 46% !important;
  }
  .viewall_section .flex-dev .verticalimage{
    height: inherit;
  }
  .carousel_body .home_carosel_title h4 {
    margin-bottom: 10px;
    font-size: 15px;
  }
  /* .vjs-theme-forest .vjs-big-play-button{
    margin-top: -30px !important;
  } */
  .home_viewall {
    font-size: 13px;
    margin-top: 2px;
  }
  .carousel_titles .movie_details-div ul li{
    color:#cccccc;
    font-size: 10px;
  }
  .wrapper-logo {
    text-align: center !important;
    margin: 6px 0px;
  }
  .navbar-nav > li {
    padding: 1px 0px;
  }
  .navbar-collapse {
    box-shadow: none;
  }
  .collapse_menu {
    position: absolute;
    right: 0px;
    top: 10px;
  }
  .signin_modal h4 {
    font-size: 15px;
  }
  .dark-header {
    padding: 0px;
  }
  .search-hint.open-search {
    background-color: #171719;
    border-radius: 0;
    padding: 0 !important;
    margin: 0 !important;
    border: 0;
    top: 30px;
    width: calc(100% - 49px);
    left: 10px;
    z-index: 9999;
  }
  .label-hint input {
    background-color: #171719 !important;
    border-radius: 0 !important;
    border: 0 !important;
    /* border-bottom: 1px solid #ea6c72!important; */
    padding: 0 !important;
    margin: 0 !important;
    height: 51px;
    width: 100%;
    padding: 5px 10px !important;
    padding-left: 35px !important;
  }
  .profile_icon img {
    width: 30px;
  }
  .wrapper-header .nav .dropdown-menu {
    left: 0px;
    position: fixed;
    min-width: 130px;
  }
  .mobile_toggle > ul > li > .dropdown-menu:before {
    left: 16px;
  }
  .search-btn {
    display: none;
  }
  .search-hint {
    border: none;
    padding: 0px;
    width: 100%;
    transition: all 0.9s linear;
  }
  .profile_icon {
    bottom: 11px;
  }
  .viewall_rectangle .heading-video__title h1,
  .footer_details .footer_details_title h1 {
    font-size: 18px;
  }
  .viewall_rectangle {
    /* margin-top: 120px; */
    padding: 0px;
  }
  .viewall_rectangle h2 {
    margin: 0px !important;
  }
  .footer_details {
    padding: 0px 20px;
    margin-top: 70px;
  }
  .footer_details p {
    font-size: 13px;
  }
  .icon-caption-text h2 {
    font-size: 14px;
  }
  .footer_details h4 {
    font-size: 14px;
  }
  .right-side {
    margin: 0px auto;
  }
  .left-side {
    margin: 2px auto;
  }
  .footer-content .footer-list {
    margin-bottom: 3px;
    justify-content: center;
  }
  /* .footer-content .copyright {
    margin-bottom: 5px;
  } */
  .viewall_rectangle .heading-video__title h2 {
    margin-left: 12px;
  }
  .home_carosel_title {
    padding: 5px;
    margin-bottom: 5px;
  }
  .carousel_body .home_carosel_title .squareimage {
    width: 25px;
    padding-top: 5px;
  }
  .carousel_body .home_carosel_title .arreimage {
    width: 38px;
  }
  .carousel_body .home_carosel_title .logo {
    width: 55px;
    padding-top: 5px;
  }
  .carousel_body .home_carosel_title .hookimage {
    width: 42px;
  }
  .carousel_body .home_carosel_title .hungamaimage {
    width: 50px;
  }
  .player_section {
    margin-top: 50px;
  }
  .synopsis {
    padding: 0px 12px;
  }
  .episodes_section, .watched_carousel .episodes_section{ 
    padding: 0px 10px;
  }
  .episodes_Heading {
    color: #ffffff;
    font-size: 20px;
  }
  .episodes_info__time {
    font-size: 14px;
  }
  .movies_detail_content .movies_detail_title h1 {
    font-size: 18px;
  }
  .movie_details-div ul li {
    font-size: 14px;
  }
  .detail_synopsis .synopsis h2.title,
  .you-may-like_section h2.title {
    margin: 5px 0px;
    font-size: 15px;
  }
  .synopsis_content p {
    font-size: 13px;
  }
  .icon-caption-image {
    width: 40px !important;
    height: 100%;
  }
  .icon-caption {
    margin: 8px 0px;
  }
  .searchopen .label-hint {
    display: none;
  }
  .searchopen.open-search .label-hint {
    display: block;
    overflow: visible;
    /* transition: left .2s linear 0s!important; */
  }
  .searchclose .close_icon {
    display: none;
  }
  .searchclose.close-search .close_icon {
    display: block;
  }
  .searchclose.close-search .search_icon {
    display: none;
  }
  .modal-header h4 {
    display: none;
  }
  .page_marker1 img {
    width: 45px;
  }
  .support {
    display: none;
  }

  .searchopen {
    position: fixed;
    left: -800px;
    width: 100%;
    background: #dbdbdb;
    z-index: 9;
    border: none;
    height: 0;
    padding: 0 30px;
    overflow: hidden;
    /* transition: left 1s linear 0s!important; */
  }
  .open-search {
    top: 0px;
    width: calc(100% - 49px);
    z-index: 999999;
    left: -30px;
    overflow: visible;
  }
  .searchopen .label-hint .fa-arrow-left {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    padding: 17px 10px;
  }
  .searchopen .result_area {
    height: 100vh;
    background-color: #444;
    padding: 5px 12px;
    border-top: 3px solid #eb1e27;
  }
  .searchopen .label-hint .fa-arrow-left,
  .searchopen .result_area {
    display: block;
  }
  .searchopen .search_clear {
    width: 15px;
    position: absolute;
    right: 15px;
    top: 50px;
  }
  .searchopen .search_clear img {
    width: 100%;
  }
  .searchopen .search_clear {
    display: block;
  }
  .download-link-group img {
    width: 120px;
  }
  .signin_footer p {
    font-size: 12px;
  }
  .portalrecocolor {
    color:white;
  }
  .vodafone_login_logo img {
    width: 40px;
  }
  #hungama_Player .setting_button {
    width: 38px;
  }
  #hungama_Player .setting #settingBox {
    width: 64px;
    padding: 0px 10px;
  }
  #hungama_Player .setting #settingBox li {
    padding: 0px;
  }
  #hungama_Player #settingBox input[type="radio"] + label {
    font-size: 11px;
    margin-bottom: 0px;
  }
  #hungama_Player #hungama_logo {
    min-height: 25px;
  }
  #hungama_Player #settingBox input[type="radio"] + label span {
    width: 10px;
    height: 10px;
    margin: -1px 6px 0 0;
  }
  .movies_detail_content .movies_detail_title h4 {
    font-size: 16px;
  }
  .player_section .movie_details {
    width: 100%;
    position: relative;
    padding: 0px 20px;
  }
  .switch_light {
    display: none;
  }
  .signin_btn button {
    font-family: "Roboto", Arial;
    width: 110px;
    font-size: 14px;
  }
  .signin-part label {
    color: #ffffff;
    text-align: center;
    width: 100%;
    font-size: 13px;
  }
  .signin-part .bar {
    display: none;
  }
  .signin_portal span {
    color: #fff;
  }
  .add-watchlist-btn:hover {
    background-color: transparent;
    border: 2px solid #3c3c3c;
  }
  .title_watchlist h2 {
    font-size: 18px;
    margin-left: 30px;
  }
  .info_footer {
    margin: 10px 0px 0px 0px;
  }
  .banner_slider .owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    background: #fff;
    background: #fff;
  }
  .banner_slider .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
    background-color: #eb1e27;
  }
  .dropdown-menu-full {
    padding: 0px 40px;
  }
  .dropdown-menu-full .dropdown-footer {
    padding: 4px 0 15px;
  }
  /* .dropdown-footer .btn,
  .btn:focus,
  .btn:hover {
    min-width: 100px !important;
    margin: 0 10px !important;
    padding: 4px 12px !important;
  } */
  .search-list {
    height: 100vh;
  }
  .addto_home_btn {
    display: block;
    float: left;
    position: relative;
    top: -7px;
    left: 8px;
    font-size: 18px;
    color: #999;
  }
  .add-to-home {
    z-index: 9999 !important;
  }
  /*ADD HOME SCREEN*/
  .addto_home_btn {
    display: block;
    float: left;
    position: relative;
    top: -7px;
    left: 8px;
    font-size: 18px;
    color: #999;
  }
  .search-box {
    margin-right: 32px;
  }

  .shake {
    animation: shake-animation 10s ease infinite;
    transform-origin: 50% 50%;
  }
  .filters_flex_dev{
    position: relative;
  }
}
 

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 0);
    }
    2.57143% {
      transform: translate(0, 0);
    }
    3.35714% {
      transform: translate(5px, 0);
    }
    4.14286% {
      transform: translate(0, 0);
    }
    5.92857% {
      transform: translate(5px, 0);
    }
    6.71429% {
      transform: translate(0, 0);
    }
    7.92857% {
      transform: translate(5px, 0);
    }
    8.71429% {
      transform: translate(0, 0);
    }
    9.92857% {
      transform: translate(5px, 0);
    }
    10.71429% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  .modal.fade:not(.in).right .modal-dialog {
    -webkit-transform: translate3d(125%, 0, 0);
    transform: translate3d(125%, 0, 0);
  }
  .add-to-home.modal .modal-dialog {
    margin: 0;
  }
  .add-to-home.modal .modal-content {
    margin: 0;
    border: none;
    border-radius: 0;
    background-color: rgba(23, 23, 25, 0.8);
    color: #fff;
    /* height: 100vh;  */
  }
  .add-to-home.modal .modal-footer {
    border-top: none;
    text-align: center;
  }
  .add-to-home.modal .addhome_btn {
    background-color: #eb1e27;
    border-color: #eb1e27;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 20px;
    width: 100px;
    padding: 5px;
    margin: 0px auto;
  }
  .add-to-home.modal .modal-footer {
    padding-bottom: 40px;
  }
  .add-to-home.modal .addhome_btn:hover {
    text-decoration: none;
    background-color: #bd262d;
    border-color: #bd262d;
  }
  .wrapper-header {
    border: none;
    /* border-bottom: 1px solid #fff; */
  }

  .video-socials-list {
    display: none;
  }
  .select_options select {
    font-size: 14px;
  }
  .results_empty h2,
  p {
    font-size: 14px;
    color: #999;
  }
  .results_empty h3 {
    font-size: 15px;
  }
  .flow_player {
    min-height: 200px;
  }
  .flow_player.hungama_player {
    min-height: 0px;
  }
  .label-hint input,
  .result_area p {
    font-size: 12px;
  }
  .actionbar_about {
    width: 32px !important;
    bottom: 5px !important;
  }
  .wrapper_uptandc.footer_details .backto_previous_screen .actionbar_about{
    position: relative;
    margin-bottom: 40px;
  }
  .content_section {
    margin-bottom: 10px;
  }
  /* #footer .footer-content .footer-list li:last-child{
  width: 100%;
  text-align: center;
  margin: 2px 0px;
} */
  #footer .footer-content .footer-list li:last-child::before {
    display: none;
  }

  /* #footer .footer-content .left-side img, #footer .footer-content .right-side img{
  width: 150px;
} */

  .googlepay_img {
    width: 130px;
  }
  .apple_img {
    width: 115px;
  }
  .download-app-link {
    height: inherit;
  }
  .right-side {
    margin: 0px auto;
    text-align: center;
  }
  #search_part .actionbar_back {
    top: 44px;
  }
  .download_vfplayapp {
    text-align: center;
    margin: 0px auto;
  }
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  /* .desktop_app_version h4{display: none;} */
  .modal_infomsg .modal-body, .modal_infomsg .modal-footer{padding: 0px;}
  .info_footer {
    margin: 10px 0px 0px 30px !important;
  }
  .info_header{font-size: 14px;}
  .info_title {font-size: 12px;}
  .modal-header {padding: 15px 0 !important;}
  .play_logo {
    bottom: 10px;
    left: 0px;
  }
  .carousel_body .home_carosel_title h1{
    font-size: 18px;
    color: #ffffff;
  }
  .episodes_viewall a{font-size: 12px;}
  .banner_slider .owl-carousel .owl-item img{border-radius: 0px;}
  .flowplayer .divForward.fp-controls{right: 10% !important;}
  .flowplayer .divBackward.fp-controls{left: 10% !important;}
  .flowplayer .fp-controls img{width: 38% !important;}
  .breadcrumb_section {
    padding-left: 10px;
 }
  .breadcrumb_section ul{padding: 0px;}
  .redirect_section img{width: 100%;}
  .redirect_section{min-height: 40vh;}
  .synopsis_content.show_morelist p{height: 55px;}
  .synopsis_content h5{margin: 0px 0px 10px 0px;}

  .actor_profile_section img{
    width: 100%;
  } 
  .footer_details.login_section{
    margin-top: 100px;
  }
  .footer_details.actor_profile{
    margin-top: 118px;
  } 
  .actorprofile_body {
    padding: 0 18px;
}
.sliderbody.promotions_section{
  margin-top: 77px;
}
.survey_title{
  padding-left: 0px;
}
.add-watchlist-btn{
  width: 100%;
  padding: 7px 7px !important;
  font-size: 10px !important;
}
.footer_details{
  margin-bottom: 0px !important;
}
.footer_details h3 {
  color: #fff;
  font-size: 18px !important;
}
/* .video-js .vjs-control {
  width: 3.5em;
} */
.breaking_news_carousel, .promoStaticBanner{
  padding: 0;
}
.promoStaticBanner h1{
  font-size: 14px;
  padding: 5px;
  margin-bottom: 5px;
}


@media screen and (min-width: 768px) {
  /* .menu_mobile {
    display: none;
  } */
  .mobile_search {
    display: none;
  }
  .mobile_toggle:hover .dropdown-menu {
    display: block;
  }
  .vodafone_login_logo {
    display: none;
  }
  .signin-part label {
    display: none;
  }
  .genres_languages {
    height: 140px;
  }
  /* .actionbar_back {
    display: none;
  } */
  #search_part .actionbar_back {
    display: none;
  }
  .download_vfplayapp h4 {
    display: none;
  }
  .app_version h5 {
    display: none;
  }
  .gloabl_serach_update .search_clear img{
    cursor: pointer;
    width: 13px;
    position: relative;
    bottom: 18px;
    float: right;
    right: 30px;
  }
  .redirect_section{height: 76vh;}
  .footer_details.login_section{
    padding: 0px 30%;
  }
}
@media screen and (min-width: 320px) and (max-width: 1208px) {
  .wrapper_manu {
    display: none;
  }
  .header-menu{
    display: none;
  }
  .profile_icon {
    display: none;
  }
  /*mobile menu*/
  .menu_mobile {
    display: inline-block !important;
    /* width: 95%; */
    width: 100%;
    bottom: 0px;
    position: fixed;
    left: 0px;
    right: 0px;
    z-index: 99;
    /* background: #0F1010; */
    margin: 0 auto;
    /* bottom: 5px; */
  }
  .menu-listres {
    display: flex;
    width: 100%;
    padding: 8px 2px;
    overflow-y: scroll;
    margin-bottom: 0;
    background: #222 !important;
    /* border-radius: 30px; */
  }
  .menu-listres li {
    margin-bottom: 0px;
    width: 100%;
    display: block;
  }
  .menu_mobile .menu_list_link {
    font-size: 12px;
    display: block !important;
    text-align: center;
    color: #999;
  }
  .menu_mobile .menu_list_link .mob-icon {
    /* width: 25px;
  height: 20px; */
    margin: auto auto 2px;
    display: block;
    color: #fff;
  }
  .menu_mobile .menu_list_link.active span {
    /* color: #eb1e27; */
    /* color: #EE6D6D; */
    color: #2CD7F3;
  }
  .menu_mobile .menu_list_link.active {
    text-decoration: none;
  }
  .mobile_episodes_carousel .episodes_tabs .owl-theme .owl-nav {
    display: block;
  }
  .mobile_episodes_carousel .episodes_tabs .owl-carousel .carousel-next-slide{
    right: -10px;
  }
  .mobile_episodes_carousel .episodes_tabs .owl-carousel .carousel-prev-slide{
    left: -5px;
  }
  .mobile_episodes_carousel .episodes_tabs .owl-carousel .carousel-next-slide, 
  .mobile_episodes_carousel .episodes_tabs .owl-carousel .carousel-prev-slide{
    background-size: contain;
    top: -7px;
    width: 20px;
    height: 20px;
  }
  .mobile_episodes_carousel .carousel_body .owl-next{
    right: 0px;
  }
  .mobile_episodes_carousel .carousel_body .owl-prev{
    left: 0px;
  }
  .mobile_episodes_carousel .episodes_tabs .nav.tabs{
    padding: 0px 15px;
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .verticalimage {
    height: 110px;
  }
}
@media screen and (min-width: 320px) and (max-width: 380px) {
  .add-to-home.modal .modal-content {
    height: 100vh;
  }
  .episodes_tabs .episodes-info span, .recommended_carousel .episodes-info span {
    width: 145px;
  }
}
@media screen and (min-width: 992px) {
  #hungama_Player .setting #settingBox {
    width: 80px;
  }
  #hungama_Player #settingBox input[type="radio"] + label {
    font-size: 12px;
    margin-bottom: 0px;
  }
  #hungama_Player #settingBox input[type="radio"] + label span {
    width: 10px;
    height: 10px;
    margin: -1px 6px 0 0;
  }
  .aboutus_view{display: none;}
  .min_footer{height: 76vh;}
  .info_footer .footer-content .left-side{float: none;}
  .info_footer.modal-footer{padding: 0px; margin: 0px 0px 10px 115px;}
}
@media screen and (min-width: 1208px) and (max-width: 1368px) {
  .wrapper-header ul.wrapper_manu {
    padding: 3px 0px 0px 7px;
  }
  .navbar-nav > li {
    padding: 10px 15px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1024px){
.profile_details_modal .modal-dialog {
    width: auto;
}
}

@media (max-width: 1070px) {
  .flow_player {
    width: 100%;
  }
  .player_section .movie_details {
    width: 100%;
    position: relative;
    padding: 5px 10px;
  }
  .movies_detail_content {
    padding: 0px;
    margin-bottom: 5px;
  }
  .synopsis {
    padding: 0 !important;
    position: inherit !important;
    top: 0px !important;
  }

}
@media (max-width: 480px) {
  .video-socials-list {
    width: 100%;
    margin: 5px 0px;
  }
}
@media (max-width: 1315px) {
  .video-socials-list {
    margin: 12px 0px;
  }
}

@media (min-width: 768px) {
  .installapp {
    display: none;
  }
}
@media (min-width: 1025px) {
  .wrapper-logo{
    text-align: left;
    left: 45px;
    bottom: 4px;
  }
  .episodes_tabs .tabs .owl-nav .owl-prev{
     left: -20px;
     top: 6%;
  }
  .episodes_tabs .tabs .owl-nav .owl-next{
    right: 0px;
    top: 6%;
  }
 
  .menu_mobile{
    display: none;
  }
  .nav-mobile .menu-container .menu-sidebar{
    display: none;
  }
  .nav-mobile #menu-toggle.active ~ .menu-button:after, .nav-mobile #menu-toggle:checked ~ .menu-button:after{
    display: none;
  }
  .mnr_detail_page .fevitite_options ul li{
    width: 28%;
    margin-right: 10px;
  }
  .mnr_detail_page .fevitite_options ul li.share_details .dropdown .dropdown-title{
    border: none;
    padding: 8px 3px;
  }
  .mnr_detail_page .fevitite_options .social_detail{
    padding: 8px 3px;
  }
  
  /* .mobile_favourite_options{
    display: none;
  } */
  
  .breaking_caption{
    display: none;
  }
  .episodes_tabs .tab_item {
    text-align: left;
    margin-bottom: 10px;
  }
  .white-theme .wrapper-header{
    background-color: #171717;
  }
  .white-theme .hamburger_line, .white-theme .hamburger_line::before, .white-theme .hamburger_line::after {
    background-color:#ffffff;
  }
  .white-theme .mainlogo_dark, .white-theme .mobile_search_dark {
    display: inline-block;
  }
  .white-theme .mainlogo_white, .white-theme .mobile_search_white {
    display: none;
  }
  .mnr_detail_page .fevitite_options .social_detail p{
    font-size: 12px;
  }
  .carousel_body .home_carosel_title h1{
    width: 350px;
  }
  .dropdown-menu .menuitem_highlighted_icon{
    display: none;
  }
  .breaking_news_carousel .carousel_body .home_carosel_title h1{
    padding-left: 55px;
  }
  .desktop_singlebanner{
    width: 100%;
    text-align: center;
  }
  .desktop_singlebanner img{
    width: 60%;
  }

}


@media (max-width: 1024px) {
  .wrapper-recommendations{
    display: none;
  }
  .footer-content{
    display: none;
  }
  .desktop_favourite_options, .desktop_fav_options{
    display: none;
  }
  .breaking_news_carousel .owl-dots{
    display: none; 
  }
  .mobile_episodes_section.episodes_section{
    padding: 0px;
    margin-top: 0px !important;
  }
  /* .white-theme .movie_details, .white-theme .movies_detail_content {
    background-color:#fff;
  } */
  .white-theme .movies_detail_content .mnr_detail_page .epi_description p{
    color: #666666;
  }
  .white-theme .player_section .movie_details{
     padding: 5px 0px;
  }
  .white-theme .movie_details_title{
     color:#333333;
  }
  .white-theme .movie_details_title,  .white-theme .movie_details-div, .white-theme .mobile_favourite_options,
  .white-theme .epi_description{padding: 0px 10px;}
  .white-theme .mnr_detail_page .fevitite_options ul li{
    background-color: #fff;
  }
  .white-theme .fevitite_options .social_detail img{
    padding: 10px;
    background-color:#F1F2F6;
    width: 40%;
    border-radius: 50%;
  }
  .white-theme .mobile_favourite_options ul{
    margin: 0px;
  }
  .white-theme .mnr_detail_page .fevitite_options .social_detail p{
    color: #333333;
  }
  .videojs_player_section .video-js .vjs-big-play-button {
    /* background-size: 30% 35%; */
    background-position: center !important;
    background-size: cover !important;
    width: 62px;
    height: 62px;
  }
  .footer {
    height: 35px !important;
}
.singlebannerlivevideo .promo_video{
    width: 100%;
}
.singlebannerlivevideo .promo_video h1{
    font-size: 14px;
 }
 .continuewatch_carousel .carousel_body .carousel-prev-slide, 
 .continuewatch_carousel .carousel_body .carousel-next-slide{
  display: none;
}
.carousel_body .home_carosel_title h1{
  font-size: 14px;
}
.select_options {
  text-align: center;
  float: inherit;
  margin-top: 5px;
}
}
.dark-theme .news_tab_part .news_area .news_titles h2 {
  color: #fff;
}

.dark-theme .news_tab_part .news_area .mm_banner_item h3{
  color: #fff;
}
@media (min-width: 1281px) {
 .epi_description p{
    display: inline-block;
    width: 100%;
    overflow: hidden ;
    text-overflow: ellipsis;
    height: 200px; 
  }
}
@media screen and (min-width: 1071px) and (max-width: 1280px){
  .epi_description p {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 110px;
}
}
/* iframe{
  display: none;
} */